import React from "react"
import Layout from "../components/Layout"
import { ButtonColor } from "../components/Button"
import { Result } from "antd"

const NotFoundPage = () => (
  <Layout>
    <div className="gb-container">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<ButtonColor text="Go Home" cta="/" />}
      />
    </div>
  </Layout>
)

export default NotFoundPage
