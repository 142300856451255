import React from "react"
import { Link } from "gatsby-plugin-react-intl"
import styled from "@emotion/styled"

const TransparentWrapper = styled(Link)`
  min-width: 160px;
  height: 42px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #7497b2;
  background-color: transparent;
  border: 1px solid #7497b2;
  border-radius: 5px;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
  &:hover {
    color: #fff;
    background-color: #7497b2;
    text-decoration: none;
  }
`
const ColorWrapper = styled(Link)`
  min-width: 160px;
  height: 42px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  background-color: #7497b2;
  border: 1px solid #7497b2;
  border-radius: 5px;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
  &:hover {
    color: #7497b2;
    background-color: #fff;
    text-decoration: none;
  }
`

export const ButtonTransparent = ({ text, cta }) => {
  return <TransparentWrapper to={cta}>{text}</TransparentWrapper>
}

export const ButtonColor = ({ text, cta }) => {
  return <ColorWrapper to={cta}>{text}</ColorWrapper>
}
